import { Button, Text } from '@mantine/core';
import type { HTMLAttributeAnchorTarget } from 'react';
import React, { forwardRef } from 'react';

interface NavigationItemProps {
  children: React.ReactNode;
  target?: HTMLAttributeAnchorTarget;
  onClick?(): void;
  active?: boolean;
  highlight?: boolean;
}

export const NavigationItem = forwardRef<
  HTMLAnchorElement,
  NavigationItemProps
>(function NavigationItem(props, ref) {
  const { children, active, highlight, ...rest } = props;
  return (
    <Button
      ref={ref}
      component="a"
      size="lg"
      variant="outline"
      color="blue"
      radius="md"
      sx={{ borderWidth: 0, color: 'inherit' }}
      {...rest}>
      <Text
        sx={{
          fontSize: 18,
          fontWeight: highlight ? 600 : 400,
          color: 'inherit',
        }}
        variant={highlight || active ? 'gradient' : 'text'}
        gradient={
          highlight
            ? { from: '#226cff', to: '#ff00ff', deg: 10 }
            : { from: 'blue', to: 'cyan', deg: 45 }
        }>
        {children}
      </Text>
    </Button>
  );
});
