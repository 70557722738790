import { ApolloProvider } from '@apollo/client';
import type { ColorScheme, Tuple } from '@mantine/core';
import {
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import { useColorScheme, useHotkeys } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { defaultsDeep } from 'lodash';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { MultiFactorDialog } from '../components/dialogs/multi-factor-dialog';
import { Layout } from '../components/layout/layout';
import { config } from '../config';
import { ScriptsContainer } from '../containers/scripts/scripts-container';
import { client } from '../lib/apollo-client';
import { emotionCache } from '../lib/emotion-cache';
import { fbq } from '../lib/metrics';
import { useUiStore } from '../lib/ui-store';
import { GlobalStyle } from '../styles/global-styles';
import { customTheme } from '../styles/theme';

export interface Options {
  title?: string;
  layout: {
    background: 'default' | 'dark' | 'elevated';
    hideHeaderAuth: boolean;
    empty: boolean;
  };
  authenticated?: boolean;
}

const defaultOptions = {
  layout: {
    background: 'default',
    hideHeaderAuth: false,
    empty: false,
  },
};

const defaultTheme = {
  primaryColor: 'blue',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Sora, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  colors: {
    blue: [
      '#F2F7FF',
      '#DBE9FF',
      '#DBE9FF',
      '#75A8FF',
      '#3D81FF',
      '#1A66FF',
      '#0055FF',
      '#0C54E4',
      '#1650C5',
      '#1849AA',
    ] as Tuple<string, 10>,
  },
};

const StyledThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useMantineTheme();
  const mergedTheme = { ...theme, ...customTheme };
  return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>;
};

export default function App(props: AppProps) {
  const { Component, pageProps } = props;
  const options = defaultsDeep(
    (Component as unknown as { options: Options }).options || {},
    defaultOptions
  );
  const preferredColorScheme = useColorScheme();
  const ui = useUiStore();
  const messages = {};

  const colorScheme =
    ui.colorScheme === 'auto' ? preferredColorScheme : ui.colorScheme;

  const toggleColorScheme = (value?: ColorScheme) =>
    ui.actions.setColorScheme(
      value || (colorScheme === 'dark' ? 'light' : 'dark')
    );

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return (
    <Fragment>
      <Head>
        <title>{config.websiteName}</title>
        <meta
          content="minimum-scale=1, initial-scale=1, width=device-width"
          name="viewport"
        />
        <meta content={config.websiteDescription} name="description" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?v=2"
        />
        <link rel="manifest" href="/site.webmanifest?v=2" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg?v=2"
          color="#003cff"
        />
        <link rel="shortcut icon" href="/favicon.ico?v=2" />
        <meta name="msapplication-TileColor" content="#002884" />
        <meta name="theme-color" content="#ffffff" />
        {/*
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0055ee" />
        <meta name="msapplication-TileColor" content="#0055ee" />
        <meta name="theme-color" content="#0055ee" /> */}
        {fbq.noscript()}
      </Head>

      {/* Facebook, Google Analitics and Gist */}
      <ScriptsContainer />

      <IntlProvider
        locale={ui.locale}
        timeZone="UTC"
        messages={messages}
        onError={() => void 0}>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            emotionCache={emotionCache}
            theme={{
              colorScheme,
              ...defaultTheme,
            }}>
            <StyledThemeProvider>
              <ApolloProvider client={client}>
                <NotificationsProvider>
                  <ModalsProvider
                    modalProps={{
                      radius: 'lg',
                      padding: 32,
                      styles: {
                        title: {
                          fontSize: 36,
                          lineHeight: '43px',
                          fontWeight: 600,
                          letterSpacing: -1.33,
                        },
                        close: {
                          width: 32,
                          height: 32,
                          '> svg': {
                            width: 24,
                            height: 24,
                          },
                        },
                      },
                    }}
                    modals={{
                      multifactor: MultiFactorDialog,
                    }}>
                    <Layout options={options}>
                      <GlobalStyle />
                      <Component {...pageProps} />
                    </Layout>
                  </ModalsProvider>
                </NotificationsProvider>
              </ApolloProvider>
            </StyledThemeProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </IntlProvider>
    </Fragment>
  );
}
